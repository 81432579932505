import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
    private _open: boolean = false;
    open$: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    toggleNav() {
        this._open = !this._open;
        this.open$.next(this._open);
    }
}