import { Component, OnInit } from '@angular/core';
import {
  animate, state, style, transition, trigger
} from '@angular/animations';
import { AuthService } from '@core/auth/auth.service';
import { SidenavService } from '@core/layout/sidenav/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slide', [
      state('open', style({ width: '0',  transform: 'translateX(-220px)'})),
      state('closed', style({ width: '220px',  transform: 'translateX(0)'})),
      transition('* => *', animate(150))
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'Maust Toolbox';

  constructor(public auth: AuthService, public nav: SidenavService) {}

  ngOnInit() {
    this.auth.localAuthSetup();
  }
}
