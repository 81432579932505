import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { SidenavService } from '../sidenav/sidenav.service';


@Component({
  selector: 'primary-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public auth: AuthService, public nav: SidenavService) { }

  ngOnInit() {
  }
  
}
