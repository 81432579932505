import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/auth/auth.guard';
import { CallbackComponent } from '@core/auth/callback/callback.component';


const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'profile',
    loadChildren: () => import('@features/profile/profile.module')
      .then(module => module.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'garage',
    loadChildren: () => import('@features/garage/garage.module')
      .then(module => module.GarageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
