// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AUTH_CLIENTID: 'R7cNW37GrpjRPZZaMEM1gQ8X32s8EtZI',
  AUTH_DOMAIN: 'maust.auth0.com',
  PARTICLE_DEVICE: '210043000547373336323230',
  PARTICLE_TOKEN: '709e540671b5cc4a76b5e7066745a6aa350654a7'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
